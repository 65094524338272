import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/ruby1.jpg";
import headerImg1 from "../assets/img/readme1.jpg";
import fullReadmeImg from "../assets/img/reademe_full.png";
import fullRubyImg from "../assets/img/ruby_full.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Apps = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Web Developer", "Web Designer", "UI/UX Designer" ];
  const period = 2000;
 

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="app_img" id="apps">
      <Container>

        <br></br>
        <br></br>
        <br></br>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "" : ""}>
                {/* <span className="tagline">Ruby</span> */}
                <h3 > RUBY </h3>
                  <p>Experience the joy of listening to audiobooks like never before with our modern audiobook player. This sleek device is packed with features that make listening to your favorite books a breeze.
                      With its high-quality sound and user-friendly interface, our audiobook player delivers an immersive listening experience. 
                      Whether you're commuting, working out, or just relaxing at home, you'll love how easy it is to use and how great your audiobooks sound.
</p>
                  <button onClick={() =>  window.open('https://play.google.com/store/apps/details?id=com.vestracode.audiobookplayer')}>Get it on Google Play <ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>


          <Row className="aligh-items-center">
            <TrackVisibility partialVisibility>
              {({ isVisible,hasBeenVisible }) =>
                <div className={isVisible && !hasBeenVisible ? "animate__animated animate__zoomIn" : "invisible"}>
                                    <img 
                  style={{ 
                    width: "50%", 
                    height: "40%",
                    display: "block", // Makes the image a block element
                    margin: "auto", // Centers the image horizontally
                    paddingTop: "10px" // Adds padding to the top of the image
                  }}  
                  src={fullRubyImg} 
                  alt="Header Img"/>
                </div>}
            </TrackVisibility>
        </Row>



          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  {/* <img style={{ width: "60%", height: "60%" }} src={headerImg} alt="Header Img"/> */}
                </div>}
            </TrackVisibility>
          </Col>
        </Row>

        <br></br>
        <br></br>
        <br></br>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "" : ""}>
                {/* <span className="tagline">ReadMe</span> */}
                <h3 > ReadMe </h3>
                  <p>Discover the ultimate e-book reading experience with Readme the top-rated e-book reader app for your mobile device. Our app supports popular formats like EPUB, PDF and more, making it easy to read all your favorite books on the go.
                      With Readme ebook reader you get an all-in-one e-book app that is designed to enhance your reading experience. 
                      TTS reader functions: Our powerful text-to-speech engine supports multiple languages and voices, allowing you to listen to your books without an internet connection or use additional voices with an internet connection. </p>
                      <button onClick={() =>  window.open('https://play.google.com/store/apps/details?id=com.vestracode.readme')}>Get it on Google Play <ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>

        <Row className="aligh-items-center">
            <TrackVisibility partialVisibility>
            {({ isVisible,hasBeenVisible }) =>
                <div className={isVisible && !hasBeenVisible ? "animate__animated animate__zoomIn" : "invisible"}>
                  <img 
                  style={{ 
                    width: "50%", 
                    height: "40%",
                    display: "block", // Makes the image a block element
                    margin: "auto", // Centers the image horizontally
                    paddingTop: "10px" // Adds padding to the top of the image
                  }} 
                  src={fullReadmeImg} 
                  alt="Header Img"
                />
                </div>}
            </TrackVisibility>
        </Row>

          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  {/* <img style={{ width: "60%", height: "60%" }} src={headerImg1} alt="Header Img"/> */}
                </div>}
            </TrackVisibility>
          </Col>
        </Row>

      </Container>
    </section>
  )
}
